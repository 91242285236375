


























import Vue from 'vue'

import { User } from '@/types'
import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { last } from 'lodash'
import { APP_META } from '@/consts'
import { MetaInfo } from 'vue-meta'

export default Vue.extend({
	name: 'Profile',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Profile | ${APP_META.title}`,
		}
	},
	computed: {
		...(mapGetters('auth', ['$isLoggedIn', '$currentUser']) as Accessors<{
			$isLoggedIn: boolean
			$currentUser: User
		}>),
	},
	created() {
		this.$syncStack({
			name: 'Basic Info ',
			path: last(this.$route.matched)?.path,
		})
	},
})
